import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./Home.module.scss";

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

function mergeArraysUnique(array1, array2) {
  return [...new Set([...array1, ...array2])];
}

const VideoAdd = () => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (!window._VIDEO_TIMEOUT) {
      const time = display ? 24 * 1000 : 3 * 60 * 1000;
      console.log("video", { time });
      window._VIDEO_TIMEOUT = setTimeout(() => {
        setDisplay(!display);
        window._VIDEO_TIMEOUT = false;
      }, time);
    }
  });

  if (!display) {
    return null;
  }

  return (
    <video
      className="ScreenCarousel__cta"
      autoPlay={true}
      muted={true}
      playsInline={true}
      src={new URL("video.mp4", PUBLIC_URL)}
    />
  );
};

const Home = () => {
  const images = useSelector(({ stateMachine }) => stateMachine.images);
  const [items, setItems] = useState([]);
  const prevImages = useRef(images);

  useEffect(() => {
    const merged = mergeArraysUnique(images, prevImages.current);
    const trimmed = merged.slice(0, 3);

    setItems(trimmed);

    prevImages.current = trimmed;
  }, [images]);

  const variants = {
    initial: {
      opacity: 1,
      x: "0%",
      y: "-150%",
      rotate: "5deg",
      scale: 1,
    },
    exit: {
      opacity: 0,
      x: "100%",
      y: "0%",
      rotate: "15deg",
      scale: 0.9,
      transition: {
        duration: 1,
        delay: 0.5,
        ease: "easeIn",
      },
    },
    featured: (i) => ({
      opacity: 1,
      x: "0%",
      y: "0%",
      rotate: "-4deg",
      scale: 1,
      transition: {
        duration: 1.5,
        ease: "easeOut",
      },
    }),
    side: (i) => ({
      opacity: 1,
      x: i % 2 === 0 ? "25%" : "-25%",
      y: "0%",
      scale: 0.9,
      rotate: i % 2 === 0 ? "5deg" : "8deg",
      transition: {
        duration: 1,
        ease: "easeInOut",
        delay: i * 0.3,
      },
    }),
  };

  return (
    <div className={styles.root}>
      <div className={styles.carousel}>
        <AnimatePresence>
          {items.map((src, i) => (
            <motion.div
              key={src}
              custom={i}
              initial="initial"
              exit="exit"
              animate={i === 0 ? "featured" : "side"}
              variants={variants}
              className={styles.slideWrapper}
              style={{
                zIndex: items.length - i,
              }}
            >
              <div className={styles.slide}>
                <img className={styles.image} src={src} alt={src} />
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      <VideoAdd />
    </div>
  );
};

export default Home;
